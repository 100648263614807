<template>
  <div>
    <!-- account loader -->
    <v-overlay
      :value="showPreloader"
      opacity="1"
      color="#000000"
      z-index="1000002"
      class="account-overlay"
    >
      <v-progress-linear
        color="#CF1019"
        background-color="000000"
        height="5"
        v-model="loadProcess"
      />
      <div class="loader flex-center-center">
        <img src="./../../assets/loader.gif" alt="loading" />
      </div>
    </v-overlay>

    <!-- modals -->
    <!-- unassigned tickets -->
    <v-dialog
      v-model="showUnassignedTicketsModal"
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="359"
      class="mem-dialog"
    >
      <div class="tickets-dialog">
        <i class="material-icons cursor-pointer" @click="closeTicketsDialog">
          close
        </i>
        <img src="@/assets/event_tickets.svg" alt="tickets" />
        <div class="dialog-text">{{ $t("you_have_unassigned_tickets") }}</div>
        <div class="dialog-buttons">
          <mem-button :btnType="'tertiary-light'" @click="closeTicketsDialog">
            {{ $t("later") }}
          </mem-button>
          <mem-button :btnType="'mem-primary'" @click="viewEvents">
            {{ $t("view") }}
          </mem-button>
        </div>
      </div>
    </v-dialog>

    <transition :name="transitionName" mode="out-in">
      <router-view v-if="userData"></router-view>
    </transition>
  </div>
</template>
<script>
import { uniq } from "lodash";
import { mapGetters, mapActions } from "vuex";
import { setCookieV2, getToken } from "@/cookie";
import config from "@/config";
import mixinAuthForms from "./mixinAuthForms";
export default {
  name: "AuthWrapper",
  mixins: [mixinAuthForms],
  data: () => ({
    showPreloader: false,
    loadProcess: 0,
    interval: 0,
    transitionName: "",
  }),
  components: {
    "mem-button": () => import("../../components/base/BaseButton.vue"),
  },
  computed: {
    ...mapGetters([
      "userData",
      "userAvatar",
      "isAuthorized",
      "i18nTranslations",
      "unassignedTickets",
      "showCountryOverlay",
      "showUnassignedTicketsModal",
    ]),
    isMobile() {
      // TODO: Find a way to avoid vuetify variable use
      return this.$vuetify.breakpoint.width < 899;
    },
    tickets() {
      return uniq(this.unassignedTickets);
    },
  },
  methods: {
    ...mapActions(["getUserData", "handleOverlay", "loadAbuseWords"]),
    simulateLoadProcess() {
      this.interval = setInterval(() => {
        if (this.loadProcess === 100 || !this.showPreloader)
          clearInterval(this.interval);
        this.loadProcess += 2;
      }, 100);
    },
    viewEvents() {
      this.closeTicketsDialog();

      if (this.tickets.length === 1)
        this.$router.push({ path: `/events/${this.tickets[0]}` });
      else this.$router.push({ path: "/events" });
    },
  },
  watch: {
    userAvatar(status) {
      if (status && this.i18nTranslations) this.showPreloader = false;
    },
    i18nTranslations(status) {
      if (status && this.userAvatar) this.showPreloader = false;
    },
    $route() {
      this.transitionName = "slide-y-reverse-transition";
      if (!this.isAuthorized) return;
    },
    isAuthorized: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$cable.connection.connect(
            `${config.socketConnectionUrl}?token=${getToken()}`
          );
        } else {
          this.$cable.connection.disconnect();
        }
      },
    },
  },
  async created() {
    if (this.isAuthorized) return;
    this.simulateLoadProcess();
    this.showPreloader = true;
    try {
      await this.getUserData();
      // Apple sign up form redirect
      if (!this.userData.email) {
        setTimeout(() => {
          this.showPreloader = false;
        }, 300);
        return this.$router.push({
          name: "formSignUpApple",
          query: {
            // redirect: window.location.href,
            routePath: this.$route.path,
            routeName: this.$route.name,
            ...this.$route.query,
          },
        });
      }

      // show country select overlay
      if (!this.userData.spartan_country_code) {
        this.showPreloader = false;
        this.handleOverlay("country");
      }
      // unassigned tickets dialog
      if (this.userData.spartan_country_code && this.tickets.length !== 0) {
        this.showTicketsDialog(this.$route.name);
      }
    } catch (err) {
      console.log(err, "Auth error");
      setCookieV2("user", "", { "max-age": -1 });
      this.showPreloader = false;
      this.$router.push({
        name: this.$route.name === "checkout" ? "formLogin" : "formSignIn",
        query: { redirect: window.location.href, ...this.$route.query },
      });
    }
    await this.loadAbuseWords();
    setTimeout(() => {
      this.showPreloader = false;
    }, 5000);
  },
  destroyed() {
    this.$cable.connection.disconnect();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.account-overlay {
  --toolbar-height: 80px;

  margin-top: var(--toolbar-height);
  @media screen and (max-width: 1279px) {
    --toolbar-height: 65px;
  }
  .loader {
    height: calc(100vh - (var(--toolbar-height) + 5px));
    img {
      height: 80px;
      width: 80px;
    }
  }
}
.tickets-dialog {
  background: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 34px 24px 20px 24px;
  border-radius: 10px;
  i {
    color: #000000;
    position: absolute;
    top: 12px;
    right: 8px;
    font-size: 30px;
  }
  img {
    height: 74px;
    width: 74px;
    margin: auto;
  }
  .dialog-text {
    margin: 23px 0 43px 0;
    color: #000000;
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
    text-align: center;
  }
  .dialog-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    button {
      height: 36px;
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
    }
  }
}
</style>
